@font-face {
  font-family: "ClashGrotesk";
  src: url("../assets/font-family/ClashGrotesk-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/font-family/Inter-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/font-family/Inter-Medium.ttf");
  font-weight: bold;
}
