@import "styles/main.scss";

.footer {
  padding-bottom: 10rem;

  @include responsive(tablet) {
    padding-bottom: 0;
  }

  :global {
    .footer-container {
      @include container;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive(tablet) {
        justify-content: start;
        margin-top: $space-10;
      }

      @include responsive(small) {
        justify-content: flex-end;
        margin-top: 0;
      }

      .menu-list {
        display: flex;
        align-items: center;
        gap: $space-16;

        .item-menu {
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          gap: $space-2;
          position: relative;
          cursor: pointer;
          transition: 0.3s all;
          font-weight: bold;
          border-bottom: 1px dashed transparent;

          @include responsive(tablet) {
            padding-bottom: $space-2;

            &:hover {
              border-bottom: 1px dashed $text-link-hover;

              a {
                color: $text-link-hover;
              }
            }
          }

          &::after {
            content: "";
            position: absolute;
            right: -3.2rem;
            top: calc(50% - 4px);
            transform: translateY(-50%);
            height: 50%;
            width: 1px;
            background-color: rgb($text-link-hover, 0.2);
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          a {
            line-height: 100%;
            display: flex;
            align-items: center;
            gap: $space-1;
            color: $text-link;
            transition: 0.3s all;
          }

          .text-menu {
            display: none;

            @include responsive(tablet) {
              display: block;
            }
          }
        }
      }
    }
  }
}
