$background: #f0c43f;
$background-button: #2e2e2e;
$background-hover: #454545;

$text-color: #0f0f0f;
$text-secondary: #6e6e6e;
$text-link: #0f0f0f;
$text-link-hover: #454545;
$text-button: #ffffff;

$text-disable: #6e6e6e;

// $background: #0a0d14;
// $background-button: #202e79;
// $background-hover: #283a97;

// $text-color: #ffffff;
// $text-secondary: #bbbdc1;

// $text-disable: #797d86;
