@import "styles/main.scss";

.myButton {
  :global {
    .button-content {
      padding: $space-3 $space-8;
      background-color: $background-button;
      color: $text-button;
      cursor: pointer;
      transition: 0.3s all;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: $space-1;
      font-weight: bold;
      min-height: 4.8rem;
      font-size: 1.8rem;
      font-family: "Inter";

      &:hover {
        background-color: $background-hover;
      }

      &:active {
        background-color: #0f0f0f;
      }
    }
  }
}
