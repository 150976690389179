@import "styles/main.scss";

.header {
  :global {
    .header-container {
      @include container;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 999;

      .left-logo {
        width: 3.2rem;
        z-index: 999;

        @include responsive(mobile) {
          width: 12rem;
        }

        .logo {
          width: 100%;
        }
      }

      .icon-dash-box {
        z-index: 999;
        padding: $space-2;

        @include responsive(mobile) {
          display: none;
        }

        &:active {
          background-color: rgba($text-color, 0.1);
        }

        .icon-dashboard {
        }
      }

      .right-menu {
        display: flex;
        flex-direction: column;
        gap: $space-16;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 100;
        height: 100vh;
        width: 100%;
        padding: $space-12 + 6.2rem 2rem 0;
        transition: 0.3s left;
        background-color: $background;

        @include responsive(tablet) {
          background-color: transparent;
        }

        &--show {
          left: 0;
        }

        @include responsive(mobile) {
          padding: unset;
          flex-direction: row;
          align-items: center;
          height: unset;
          position: unset;
          width: unset;
        }

        .item-menu {
          font-size: 3.2rem;
          position: relative;
          z-index: 0;
          cursor: pointer;
          transition: 0.3s all;
          display: flex;
          align-items: center;
          color: $text-color;
          border-bottom: 1px dashed transparent;
          line-height: unset;

          @include responsive(mobile) {
            color: $text-secondary;
            font-size: 1.6rem;
          }

          @include responsive(tablet) {
            padding-bottom: $space-1;
          }

          &--build-with-us {
            @include responsive(mobile) {
              display: none;
            }

            @include responsive(small) {
              display: block;
            }

            .button-link {
              background-color: transparent;
              border: none;
              color: $text-link;
              font-size: 3.2rem;
              cursor: pointer;

              @include responsive(small) {
                font-size: 1.6rem;
              }
            }
          }

          @include responsive(tablet) {
            &:hover {
              border-bottom: 1px dashed $text-link-hover;
              color: $text-link-hover;

              a,
              .button-link {
                color: $text-link-hover;
              }
            }
          }

          &--coming {
            cursor: default;
            color: $text-color;

            @include responsive(tablet) {
              color: $text-color;
            }

            &:hover {
              border-bottom-color: transparent;
              // color: $text-link-hover;
            }
          }

          a {
            color: $text-link;
            line-height: unset;
            transition: 0.3s all;

            @include responsive(mobile) {
              color: $text-link;
            }
          }

          .tooltip {
            width: max-content;
            position: absolute;
            top: 100%;
            height: 0;
            transition: 0.3s height;
            font-size: 1.2rem;
            color: $text-disable;

            @include responsive(small) {
              left: 50%;
              font-size: 1rem;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}
