.app {
  min-height: 75vh;
  position: relative;
  z-index: 0;
  @include container;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include responsive(tablet) {
    justify-content: center;
    height: 85vh;
    margin-left: $space-4;
  }

  @include responsive(small) {
    height: 80vh;
  }

  @include responsive(screen2k) {
    margin: 0 auto;
  }

  .icon-logo-btn {
    width: 1rem;
  }

  .title-content {
    font-size: 3.2rem;
    line-height: 1.1;
    width: max-content;
    font-family: "ClashGrotesk";
    padding-bottom: $space-6;
    padding-right: $space-6;

    @include responsive(tablet) {
      padding: $space-8 $space-12 $space-6;
      font-size: 7.2rem;
    }
  }

  .btn-action {
    padding: $space-3 $space-12 $space-8;
    width: max-content;
    display: none;

    @include responsive(tablet) {
      display: block;
    }

    .btn-popup {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }

  .text-description {
    padding-top: $space-3;
    left: $space-5;
    bottom: -5rem;
    padding: 0;
    margin: 0 auto;
    font-size: 1.4rem;
    color: $text-color;
    line-height: 1.6;

    @include responsive(tablet) {
      font-size: 1.6rem;
      bottom: -2rem;
      left: $space-6;
      position: absolute;
    }

    @include responsive(small) {
      padding-top: $space-6;
      width: 50%;
    }

    @include responsive(mac-13) {
      left: $space-12;
    }

    @include responsive(mac-16) {
      width: 45%;
      left: $space-12;
    }
  }

  .btn-mobile {
    width: 100%;
    padding: $space-4 0 $space-8;
    // background-color: $background;

    @include responsive(tablet) {
      display: none;
    }

    .react-typeform-embed {
      width: 100%;
    }

    .custom-btn {
      .button-content {
        width: 100%;
        justify-content: center;
      }
    }
  }

  // .background-img {
  //   @include container;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: $space-5;
  //   // padding: 0 $space-5;

  //   // @include responsive(tablet) {
  //   //   padding: 0 $space-6;
  //   // }

  //   // @include responsive(mac-13) {
  //   //   padding: 0 $space-12;
  //   // }

  //   .icon-arrow {
  //     transition: 0.5s all;
  //     font-size: 1.4rem;
  //     opacity: 0.14;
  //   }
  // }

  .icon-logo {
    position: absolute;
    top: -35rem;
    right: -30rem;
    width: 65rem;
    height: 65rem;
    z-index: -1;

    @include responsive(tablet) {
      width: 80vh;
      height: 80vh;
    }

    @include responsive(small) {
      bottom: -35rem;
      top: unset;
      width: 110vh;
      height: 110vh;
    }

    .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      &--small {
        width: 60%;
        height: 60%;
        transform: translate(-50%, -50%) rotate(45deg);
        animation: rotate-reverse 150s linear infinite;
      }

      &--big {
        transform: translate(-50%, -50%);
        animation: rotate 150s linear infinite;
      }
    }
  }
}
